import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import UserResetPassword from '../components/user/user-reset-password';
import crmClientService from '../services/crmClientService';

const CustomerResetPage = ({location:{search = ''}}) => {
    const qs = new URLSearchParams(search);
    const token = qs.get('t') || '';
    return (
        <Layout>
          <SEO title="User Password Reset" />
          <UserResetPassword token={token} />
       </Layout>
    );
};

export default CustomerResetPage;
